import React, { Component } from "react";
import "../../assets/css/custom.css";
import InfoMarker from "./markers/InfoMarker";
import PlusMarker from "./markers/PlusMarker";
import WorldMarker from "./markers/WorldMarker";
import ExternalLinkMarker from "./markers/ExternalLinkMarker";
import InternalLinkMarker from "./markers/InternalLinkMarker";
import PlayMarker from "./markers/PlayMarker";
import HatChefMarker from "./markers/HatChefMarker";
import SpecsMarker from "./markers/SpecsMarker";
import PromoMarker from "./markers/PromoMarker";
import ShowPriceMarker from "./markers/ShowPriceMarker";
import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import { isModalOpen } from "../../redux/carousel/action";
import CarouselMarker from "./markers/CarouselMarker";

var marker = serverResponse.config.marker
  ? serverResponse.config.marker
  : "circle_black";
const selected = require("assets/images/" + marker + ".svg");

class CustomProduct extends Component {
  state = {
    childOpen: false,
    tooltipOpen: false
  };

  navMarkers = (newMarker) => {
    var element = document.getElementById(
      newMarker + "-" + this.props.info.item_id
    );
    element.click();
  };

  hasTooltip = () => {
    let result = this.props.info.type !== "product";

    return result && this.props.info.markers[0].data.tooltip !== "";
  };

  render() {
    const styleProduct = {
      position: "absolute",
      display: this.props.info.blueprint !== null ? "flex" : "none",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      top:
        this.props.info.blueprint !== null
          ? this.props.info.blueprint.top + "%"
          : "0%",
      left:
        this.props.info.blueprint !== null
          ? this.props.info.blueprint.left + "%"
          : "0%",
      width:
        this.props.info.blueprint !== null
          ? this.props.info.blueprint.width + "%"
          : "0%",
      height:
        this.props.info.blueprint !== null
          ? this.props.info.blueprint.height + "%"
          : "0%"
    };

    let markers = this.props.info.markers.map((element, key) => {
      switch (element.type) {
        case "plus": {
          if (!this.props.info.pam)
            return (
              <PlusMarker
                zoom={this.props.zoom}
                disableSwipe={this.props.disableSwipe}
                enableSwipe={this.props.enableSwipe}
                key={key}
                info={this.props.info}
                markerInfo={element}
                leafletSwipe={this.props.leafletSwipe}
                navMarkers={this.navMarkers}
                setSwipe={this.props.setSwipe}
                isSwipeDisabed={this.props.isSwipeDisabed}
              />
            );
          else break;
        }
        case "info": {
          return (
            <InfoMarker
              zoom={this.props.zoom}
              disableSwipe={this.props.disableSwipe}
              enableSwipe={this.props.enableSwipe}
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "world": {
          return (
            <WorldMarker
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "hat-chef": {
          return (
            <HatChefMarker
              zoom={this.props.zoom}
              disableSwipe={this.props.disableSwipe}
              enableSwipe={this.props.enableSwipe}
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "play": {
          if (this.props.info.type === "product") {
            return (
              <PlayMarker
                type={"product"}
                zoom={this.props.zoom}
                disableSwipe={this.props.disableSwipe}
                enableSwipe={this.props.enableSwipe}
                key={key}
                info={this.props.info}
                markerInfo={element}
                leafletSwipe={this.props.leafletSwipe}
                navMarkers={this.navMarkers}
                setSwipe={this.props.setSwipe}
                isSwipeDisabed={this.props.isSwipeDisabed}
              />
            );
          } else {
            if (element.data.open_modal) {
              return (
                <PlayMarker
                  zoom={this.props.zoom}
                  disableSwipe={this.props.disableSwipe}
                  enableSwipe={this.props.enableSwipe}
                  key={key}
                  info={this.props.info}
                  markerInfo={element}
                  leafletSwipe={this.props.leafletSwipe}
                  navMarkers={this.navMarkers}
                  setSwipe={this.props.setSwipe}
                  isSwipeDisabed={this.props.isSwipeDisabed}
                />
              );
            } else if (!element.data.open_modal) {
              if (element.data.video_type === "youtube") {
                return (
                  <div
                    key={key}
                    className="video-responsive"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <iframe
                      title="Video"
                      width="100%"
                      height="100%"
                      src={
                        "https://www.youtube.com/embed/" +
                        element.data.link +
                        "?autoplay=1&mute=1"
                      }
                    />
                  </div>
                );
              } else {
                return (
                  <div key={key} id="videoIper" style={{ height: "100%" }}>
                    <video
                      id="mio_video"
                      autoPlay="autoplay"
                      defaultmuted="true"
                      // muted
                      playsInline
                      loop
                      style={{ width: "100%" }}
                      controls
                    >
                      <source src={element.data.link} />
                    </video>
                  </div>
                );
              }
            }
          }
          break;
        }
        case "carousel": {
          return (
            <CarouselMarker
              zoom={this.props.zoom}
              disableSwipe={this.props.disableSwipe}
              enableSwipe={this.props.enableSwipe}
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "specs": {
          return (
            <SpecsMarker
              zoom={this.props.zoom}
              disableSwipe={this.props.disableSwipe}
              enableSwipe={this.props.enableSwipe}
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "promo": {
          return (
            <PromoMarker
              zoom={this.props.zoom}
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "video": {
          return (
            <div key={key} id="videoIper" style={{ height: "100%" }}>
              <video
                id="mio_video"
                autoPlay="autoplay"
                defaultmuted="true"
                // muted
                playsInline
                loop
                style={{ width: "100%" }}
                controls
              >
                <source src={this.props.info.markers[0].data} />
              </video>
            </div>
          );
        }
        case "external_link": {
          return (
            <ExternalLinkMarker
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        case "internal_link": {
          return (
            <InternalLinkMarker
              key={key}
              info={this.props.info}
              markerInfo={element}
              leafletSwipe={this.props.leafletSwipe}
              navMarkers={this.navMarkers}
              setSwipe={this.props.setSwipe}
              isSwipeDisabed={this.props.isSwipeDisabed}
            />
          );
        }
        default:
          return null;
      }
      return 0;
    });

    let markers_2 = [];
    if (this.props.info.hidden_price) {
      markers_2 = this.props.info.markers.map((element, key) => {
        switch (element.type) {
          case "play": {
            return (
              <ShowPriceMarker
                key={key + 10}
                info={this.props.info}
                markerInfo={element}
                leafletSwipe={this.props.leafletSwipe}
                navMarkers={this.navMarkers}
                setSwipe={this.props.setSwipe}
                isSwipeDisabed={this.props.isSwipeDisabed}
              />
            );
          }
          default:
            return null;
        }
      });
    }
    markers = markers.concat(markers_2);

    var id_select = "selected-" + this.props.info.item_id;
    var id_div = "div-" + this.props.info.item_id;

    return (
      <div
        onMouseEnter={() => {
          this.setState({ tooltipOpen: true });
        }}
        onMouseLeave={() => {
          this.setState({ tooltipOpen: false });
        }}
        title={""}
        id={id_div}
        className="customProduct"
        style={styleProduct}
        onClick={(e) => {
          //controllo che sia stato cliccato il quadrato

          if (this.props.info.type === 'carousel') {
            document.querySelector('#carousel-' + this.props.info.item_id).click()
            return
          }

          if (this.props.carouselIndex.zoomValue > 1) {
            return;
          }
          if (e.target.classList.contains("customProduct")) {
            var plus = document.getElementById(
              "plus-" + this.props.info.item_id
            );
            var showprice = document.getElementById(
              "showprice-" + this.props.info.item_id
            );

            var markers_type = this.props.info.markers.map((element, key) => {
              return element.type;
            });
            if (this.props.info.hidden_price) {
              showprice.click();
            } else {
              if (this.props.info.pam) {
                var test = document.getElementById(
                  "world-" + this.props.info.item_id
                );
                if (test == null) {
                  test = document.getElementById(
                    "play-" + this.props.info.item_id
                  );
                }
                test.click();
              } else if (markers_type.includes("plus")) {
                plus.click();
              } else {
                var interactivity = null;
                switch (markers_type[0]) {
                  case "info": {
                    interactivity = document.getElementById(
                      "info-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "world": {
                    interactivity = document.getElementById(
                      "world-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "hat-chef": {
                    interactivity = document.getElementById(
                      "hatchef-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "play": {
                    interactivity = document.getElementById(
                      "play-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "specs": {
                    interactivity = document.getElementById(
                      "specs-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "promo": {
                    interactivity = document.getElementById(
                      "promo-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "external_link": {
                    interactivity = document.getElementById(
                      "world-" + this.props.info.item_id
                    );
                    break;
                  }
                  case "internal_link": {
                    interactivity = document.getElementById(
                      "world-" + this.props.info.item_id
                    );
                    break;
                  }
                  default:
                    return null;
                }
                if (interactivity != null) {
                  interactivity.click();
                }
              }
            }
          }
        }}
      >
        {serverResponse.config.marker_product_in_list &&
          this.props.groceryList.list.filter(
            (el) => el.item_id === this.props.info.item_id
          ).length > 0 && (
            <img
              src={selected}
              style={{
                position: "absolute",
                display: "block"
              }}
              alt="prodotto selezionato"
              id={id_select}
              onClick={() => document.getElementById(id_div).click()}
            />
          )}

        {markers}
        {this.state.tooltipOpen && this.hasTooltip() && (
          <div
            className="custom-tooltip"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              position: "absolute",
              top: "10%",
              backgroundColor: "var(--primary-color)",
              borderRadius: "0.25rem",
              padding: "0.25rem",
              color: "white"
            }}
          >
            <p>{this.props.info.markers[0].data.tooltip}</p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel,
    groceryList: state.groceryList
  };
};

export default connect(mapStateToProps, { isModalOpen })(CustomProduct);
